.App {
  /* text-align: center; */
  background-color: #222222; /* Update to the black color */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: white;
  background-color: #222222; /* Update to the black color */
}

.navbar {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
 
  align-items: flex-end;
  padding: 20px;
}

.navbar a {
  color: white;
  text-decoration: none;
  margin-bottom: 15px;
}

.navbar a.active {
  border-bottom: 2px solid #61dafb;
  font-weight: bold;

  transition: border-bottom 0.3s ease-in-out;
}
.navbar a:hover {
  border-bottom: 2px solid #61dafb;
  font-weight: bold;
  transition: border-bottom 0.3s ease-in-out;
}

.active {
  text-decoration: underline;
  transition: text-decoration 0.5s;
}

.content-section {
  margin-top: 20px;
  height: 800px; 
}

.button {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px 2px;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  color: white;
  background-color: #222222; /* Update to the black color */
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: 2px solid white; /* Set border color to white */
}

.button:hover {
  background-color: #111111; /* Darker shade for hover effect */
}
@keyframes spinAndGrow {
  0% { transform: scale(1) rotate(0deg); }
  50% { transform: scale(1.8); }
  100% { transform: scale(1) rotate(360deg); }
}

.social-icons a:hover {
  animation: spinAndGrow 0.8s linear infinite;
}



@media (max-width: 768px) {
  .navbar {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: #222222;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
  }

  .navbar.open {
    transform: translateX(0);
  }

  .navbar a {
    margin: 20px 0;
  }
  

  .navbar-toggle {
    position: fixed;
    top: 10px;
    right: 10px;
    display: inline-block;
    cursor: pointer;
  }
}



@keyframes shadow {
  0% {
    box-shadow: 0 0 170px 120px rgba(255, 255, 255, 0.8); /* Slightly smaller glow to start with */
  }
  50% {
    box-shadow: 0 0 200px 160px rgba(255, 255, 255, 0.8); /* Full glow */
  }
  100% {
    box-shadow: 0 0 170px 120px rgba(255, 255, 255, 0.8); /* Return to the smaller glow */
  }
}
.mouse-animation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  z-index: 9999;
  animation: shadow 1.7s infinite alternate ease-in-out; /* Slow down the animation */
  
}

.mouse-animation::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200%;
  height: 200%;
  background-image: radial-gradient(
    circle at center,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 100%
  ); /* Create a radial gradient effect */
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s ease-out; /* Add a smooth transition effect */
  
}

.mouse-animation:hover {
  transform: translate(-50%, -50%) scale(1.5); /* Increase the size of the element on hover */
}

.mouse-animation:hover::before {
  opacity: 1; /* Show the radial gradient effect on hover */
}

/* This makes the scrolling behavior smooth */
html {
  scroll-behavior: smooth;
}