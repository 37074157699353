 #experience .content-section{
    height: auto;
    margin-right: 260px;
}
#experience .vertical-timeline-element-date {
    color:#999; /* Update the color to your desired value */
  }
  .experience-header {
    font-size: 22px;  /* Change this to the desired font size */
    margin-left: 35px;
  }
#experience .vertical-timeline-element-title{
    font-size: 30px;
}
 #experience .invisible-link {
    color: inherit;
    text-decoration: none;
    
  }

  .vertical-timeline-element--work {
    cursor: pointer;
  }

  .timeline-element:hover {
    transform: scale(1.05); /* Increase the size by 5% when hovered */
    transition: transform 0.3s ease-in-out; /* Make the transform transition smooth */
  }
  @media (max-width: 768px) {
    #experience .content-section {
      margin-right: 0;
    }
  
    #experience .vertical-timeline-element-title{
      font-size: 22px;
    }
  
    #experience .vertical-timeline-element-content p{
      font-size: 14px;
    }
  }

  