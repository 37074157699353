/* Home.css */

#home .content-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;  /* Align items to the start (left for LTR languages) */
    padding: 20px;
    text-align: left;  /* Align text to the left */
    margin-top: 120px; /* Adjust the margin-top value to reduce the space */
    margin-right: 260px;
    
  }

  #home .content-section h1,
.content-section h2 {
  margin-bottom: -40px; /* adjust as needed */
}

  
  #home h1 {
    font-size: 2.5em;
    color: white;
    margin-left: 14px;
  }
 #home h3 {
    font-size: 2.5em;
    color: #a7aeb4;
    margin-bottom: -20px;
    margin-top: 20px;
    margin-left: 14px;
  }
 #home h2 {
    font-size: 20px;
    color: white;
    margin-left: 15.5px;
  }
  
 #home p {
    margin-top: 50px;
    font-size: 16px;
    color: #999;
    margin-bottom: 40px;
    margin-left: 18px;
  }
  
  button {
    /* margin-top: 25px; */
    font-size: 20px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #222222; /* Update to the black color */
    color: white;
    cursor: pointer;
    
    border: 2px solid white; /* Set border color to white */
    transition: opacity 2s;
  }
  
  button:hover {
    background-color: #111111; /* Darker shade for hover effect */
  }
  button.fade-in {
    display: inline-block;
  }
  
  .fade-in {
    animation: fadeIn 2s ease-in forwards;
  }

  .social-icons {
    display: flex;
    gap: 15px;
    margin-top: 5px;

  }
  
  .social-icons a {
    color: white;
    transition: color 0.3s;
  }
  
  .social-icons a:hover {
    color: #999;
  }
  
  .contact-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 300px;  
    margin-left: 18px;
  }
  
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  @media screen and (max-width: 768px) {
    #home .content-section {
      margin-top: 50px;
      margin-right: 20px;
    }
    #home h1{
      margin-top: 40px;
    }
    #home h3{
      margin-top: 40px;
    }
  }